import '../src/jquery';
import '../src/select2';

import 'jquery-ui';

import 'bootstrap';
import 'jquery-toast-plugin';

import 'perfect-scrollbar/jquery';
import 'jquery-sparkline';
// import '../src/footable.min';
import 'moment';

import '../src/pdfMake';
import '../src/datatables';

import '../src/eliteadmin/index';

import Rails from "@rails/ujs";
// 啟動 rails/ujs
Rails.start();
